<!-- Hero Banner -->

<div id="gem-01" class="container-fluid">
  <div class="container">
       <div class="row">
          <div id="head-info" class="col-lg-6">
            <h1 id="connectWithGuest">Committed to Excellence</h1>
            <p id="heading1" class="heading-01">We partner with brands to build and <u>&nbsp;{{wordCycle}}&nbsp;</u></p>
            <p id="heading2" class="heading-02 float-left">relationships with their guests.</p>
          </div>
          <div class="col-lg-6"></div>
          <div class="container">
            <div class="row">
              <a class="main-btn" href="javascript:void(0);" (click)="scrollToConnect()">Start a Conversation with GEM</a>
            </div>
          </div>
       </div>
  </div>
</div>

<!-- Grey Banner Highlight -->
<div class="container-fluid">
  <div class="row">
  <div  id="bannerHighlight"></div>
  <div id="bannerClear"></div>
 </div>
</div>

<!-- End  Hero banner -->


<!-- Who we are section -->
  <div class="container-fluid" id="whoWeAreSectoin">
    <div class="container">
        <div class="row">
          <h2 id='whoWeAre'>Who we are</h2>
        </div>
        <div class="row">
          <h1 id='gem'>About Guest Experience Maximization (GEM)</h1>
        </div>
        <div class="row">
          <div class="col-6" id="whoWeAreParagraph">
            <p id="whoWeAreParagraph1">
              IoT is our sandbox.
            </p>
            <p>
              GEM is a Cloud Software Developer, Experience Design Architect, and Operations Expert. By harnessing a  hands-on technology consultancy we specialize in creating tailor-made, bespoke solutions that support all stakeholder visions. GEM significantly impacts brand, guest, customer, or a patient brand journey, engagement and lifetime value, at SCALE.
            </p>
            <p>
              At our core, we are a collection of passionate, smart, curious professionals who understand the power of building long-term, professional relationships, by living up to our promise, daily.
            </p>
            <p>
              Guest Experience is our DNA. Guest preferences, personalizing experiences, and delighting guests is our laser focus. Leveraging an ‘Anything is Possible’ service heritage and mentality, and priding ourselves as expert communicators, we earn TRUST and never take it for granted.
            </p>
          </div>
          <div class="col-6" id="gemCyle">
            <img id="gemCycleDiagram" class="mx-left d-block" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/AboutUs/GEM-Cycle.svg" alt="Cycle of Guest Experience Maximization">
          </div>
          <div class="w-100"></div>
        </div>
    </div>
  </div>

  <!-- What makes us different section -->
  <div class="container-fluid" id="vision">
    <div class="container">
      <div class="row">
          <div class="col-4" id="visionTitles">
            <p id="visionSubTitle">Our</p>
            <p id="visionTitle">Vision</p>
          </div>
        <div class="col-8" id="visionParagraphContainer">
          <p id="visionParagraph" class="col-11">
            To establish, build, and strengthen relationships by providing a better customer experience to create an emotional connection and earn the trust of our partners and their guests.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" id="visionMobile">
      <div class="row">
          <div id="OurVisionMobile">
            OUR VISION
          </div>
      </div>
      <div class="row">
            <div id="OurVisionParagraphMobile">
              To establish, build, and strengthen relationships by providing a better customer experience to create an emotional connection and earn the trust of our partners and their guests.
            </div>
      </div>
  </div>
<!-- What Makes us different Section-->
  <div id="differenceContainer"class="container-fluid">
    <div class="row">
      <h1 id="whatWeDoSubtitle"class="mx-auto d-block">What we do</h1>
    </div>
    <div class="row">
      <h1 id="whatMakesUsDifferent" class="mx-auto d-block">What makes us different</h1>
    </div>

<!-- Difference Container for 3 Columns -->

    <div class="container" id="whatMakesUsDifferentContainer">
      <div class="row">
        <div class="col-sm-4">
          <div id="differenceTitleRow" class="row mx-auto">
            <p id="differenceTitle" class="mx-auto d-block">Specialized Teams</p>
            </div>
        </div>
        <div class="col-sm-4">
          <div id="differenceTitleRow" class="row mx-auto">
            <p id="differenceTitle" class="mx-auto d-block">Full-Service Approach</p>
            </div>
        </div>
        <div class="col-sm-4">
          <div id="differenceTitleRow" class="row mx-auto">
            <p id="differenceTitle" class="mx-auto d-block">Bespoke Solutions</p>
            </div>
        </div>
      </div>

  <!-- Title container ends and the Column Content Begins-->
    <div class="row">
    <div id="differenceParagraphColumn"class="col-sm-4 d-flex align-items-stretch">
      <div id="differenceParagraphCard" class="card rounded-0">
        <div class="card-body">
          <p id="differenceParagraph" class="card-text">We are an efficient set of Subject Matter Experts, tackling every endeavor to ensure the accurate resources are dedicated to achieve results with velocity and value in order to elevate a Guest Experience with clearly attributable ROI for our Partners.</p>
        </div>
        <img id="specializedImage" class="card-img-button" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/AboutUs/Specialized-Teams.jpg" alt="A group of coworkers in a conference room speaking together">
      </div>
    </div>
    <div id="differenceParagraphColumn"class="col-sm-4 d-flex align-items-stretch">
      <div id="differenceParagraphCard" class="card rounded-0">
        <div class="card-body">
          <p id="differenceParagraph" class="card-text">We excel in problem-solving. Our specialists analyze and identify root-causes to strategize, build, and implement solutions.</p>
        </div>
        <img id="serviceImage" class="card-img-button" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/AboutUs/Full-Service-Approach.jpg" alt="A group of coworkers looking at a board and strategizing">
      </div>
    </div>
    <div id="differenceParagraphColumn"class="col-sm-4 d-flex align-items-stretch">
      <div id="differenceParagraphCard" class="card rounded-0">
        <div class="card-body">
          <p id="differenceParagraph" class="card-text">We work with our clients to identify challenges and overcome them by <b>innovating customized</b> solutions and services.</p>
        </div>
        <img id="bespokeImage" class="card-img-button" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/AboutUs/Bespoke-Solutions.jpg" alt="The Guest Experience Maximization team troubleshooting Ocean Medallion technology">
      </div>
    </div>
    </div>
  </div>
  </div>
  <div class="container" id="whatMakesUsDifferentMobileContainer">
    <div class="col-10" id ="whatMakesUsDifferentCol">
        <div class="row">
            <div class="whatMakesUsDifferentItemHeader">
              Specialized Teams
            </div>
        </div>
        <div class="row">
              <div class="whatMakesUsDifferentItemParagraph">
                We are an efficient set of Subject Matter Experts, tackling every endeavor to ensure the accurate resources are dedicated to achieve results with velocity and value in order to elevate a Guest Experience with clearly attributable ROI for our Partners.
              </div>
        </div>
        <div class="row">
          <div class="whatMakesUsDifferentItemHeader">
            Full-Service Approach
          </div>
        </div>
        <div class="row">
              <div class="whatMakesUsDifferentItemParagraph">
                We excel in problem-solving. Our specialists analyze and identify root-causes to strategize, build, and implement solutions.
              </div>
        </div>
        <div class="row">
          <div class="whatMakesUsDifferentItemHeader">
            Bespoke Solutions
          </div>
      </div>
      <div class="row">
            <div class="whatMakesUsDifferentItemParagraph">
              We work with our clients to identify challenges and overcome them by <b>innovating customized</b> solutions and services.
            </div>
      </div>
    </div>
  </div>
  <div id="wrapper">
    <div class="container">
    <!-- GEM Core Values section-->
      <div class="conainer-fluid">
          <div class="row">
            <h1 id="whyWeDoSubTitle"  class="mx-auto d-block">Why we do it</h1>
          </div>
          <div class="row">
            <h1 id="coreValuesTitle" class="mx-auto d-block">GEM Core Values</h1>
          </div>
          <div class="row" id="coreValues1">
              <div class="col-6">
                <div id="valuesContainer" class="box topLine">
                  <h1 id="valueTitle">Exceed Expectations</h1>
                  <p id="valueParagraph">
                    We never settle. In the face of complexity and rapid change, we knock down barriers and build momentum to ensure the long-term success of our Partners and team members.
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div id="valuesContainer" class="box">
                  <h1 id="valueTitle">Act with Integrity</h1>
                  <p id="valueParagraph">
                    We are accountable for our actions. We build trust through candid efficient communication, operating with the highest levels of professionalism.
                  </p>
                </div>
              </div>
          </div>
      </div>

      <div class="conainer-fluid" id="coreValues2">
        <div class="row">
            <div class="col-6">
              <div id="valuesContainer" class="box">
                <h1 id="valueTitle">Deliver Value</h1>
                <p id="valueParagraph">
                  We do more with less. By being strategic in our efforts and agile in our approach, we deliver tangible business results.
                </p>
              </div>
            </div>
            <div class="col-6">
              <div id="valuesContainer" class="box">
                <h1 id="valueTitle">Prioritize People</h1>
                <p id="valueParagraph">
                  We are human-centric. By cultivating a people-first culture, we energize our team to go above and beyond to create exceptional customer experiences. We accomplish without boundaries.
                </p>
              </div>
            </div>
        </div>
      </div>
  </div>
</div>

<div id="accordion">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0 cardTitle">
        <row>
          <div data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Exceed Expectations
            <img class="vector" src="assets/Images/arrow_down.svg">
          </div>
        </row>
      </h5>
    </div>
    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <p>
          We never settle. In the face of complexity and rapid change, we knock down barriers and build momentum to ensure the long-term success of our Partners and team members.
        </p>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0 cardTitle">
        <div data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          Act with Integrity
          <img class="vector" src="assets/Images/arrow_down.svg">
        </div>
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <p>
          We are accountable for our actions. We build trust through candid efficient communication, operating with the highest levels of professionalism.
        </p>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0 cardTitle">
        <div data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Deliver Value
          <img class="vector" src="assets/Images/arrow_down.svg">
        </div>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <p>
          We do more with less. By being strategic in our efforts and agile in our approach, we deliver tangible business results.
        </p>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0 cardTitle">
        <div data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Prioritize People
          <img class="vector" src="assets/Images/arrow_down.svg">
        </div>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <p>
          We are human-centric. By cultivating a people-first culture, we energize our team to go above and beyond to create exceptional customer experiences. We accomplish without boundaries.
        </p>
      </div>
    </div>
  </div>
</div>

  <div class="container-fluid" id="ourOriginsContainerFluid">
    <div class="container">
      <div class="row">
        <h1 id="ourOriginsTitle" class="mx-auto d-block">Our Origins</h1>
        <div class="row">
          <div id="ourOriginsParagraph" class="col-6">
            <p>
              For over 25 years, we have established mutually profitable, long-term relationships and served Partners in travel and tourism, food and beverage, healthcare, and many human-centric service industries, by delivering data-driven solutions and services to better connect brands to guests, customers, or patients, enhancing relationships internally or externally.
            </p>
            <p id="ourOriginsParagraph2" >
              Built on the concept of continual improvement and adaptability in the face of change, our CEO, Steve Cohen, founded our parent company, List Management Services, Inc, (LMS) in 1994 as a data center, service bureau, specializing in source and use of Big Data.
            </p>
            <p>
              To better describe the type of solutions our teams were creating for our Partners, LMS launched the brand, Guest Experience Maximization (GEM).
            </p>
          </div>
          <div class="col-4">
            <img id="steveJohnImage" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/AboutUs/Steve-John.jpg" alt="Guest Experience Maximization leadership smiling at office party in Doral, Florida" title='Steve Cohen, Chief Executive Officer, and John Craig, Chief Operations Officer'>
          </div>
        </div>
        <div id="ourOriginsParagraph" class="row ourOriginsParagraphRow">
          <p>
            Our innovation through personalization inspired a partnership between GEM and Carnival Corporation in 2016 to assist in launching a new, IoT wearable technology, Ocean MedallionTM, to improve the customer experience. GEM joined the initiative to develop the OCEAN Medallion Experience as a Leading Partner.
          </p>
          <p>
            As we continue to create cutting-edge solutions and maximize the customer experience for the years to come, GEM will maintain an unwavering philosophy of human-centric focus, continuing to strengthen relationships and push the boundaries of innovation and service.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Leadership team section start -->

  <div id="leadershipSection"class="container-fluid">
    <div class="row">
      <h1 id="leadershipSubTitle"class="mx-auto d-block">Guest Experience Maximization</h1>
    </div>
    <div class="row">
      <h1 id="leadershipTitle" class="mx-auto d-block">Leadership Team</h1>
    </div>
  </div>

  <div id="imagesContainer" class="container-fluid">
    <div class="container">
      <div class="row" id="leadershipRow">
        <div id="imageColumn" class="col-sm-3 d-flex align-items-stretch">
          <div id="imageCard" class="card rounded-0" style="width: 18rem;">
            <div>
              <img id="steveImage" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/AboutUs/Steve+Cohen.jpg" class="square mx-auto d-block" alt="Portrait of Guest Experience Maximization CEO Steve Cohen">
              <p id="leadershipName" class="card-title">Steve Cohen</p>
              <p id="leaderTitle" class="card-subtitle mb-2">Chief Executive Officer</p>
              <a target="_blank" href="https://www.linkedin.com/in/steve-cohen-b15171/" class="stretched-link"></a>
            </div>
          </div>
        </div>
        <div class="col-sm-3 d-flex align-items-stretch">
          <div id="imageCard" class="card rounded-0" style="width: 18rem;">
            <div>
              <img id="johnImage" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/AboutUs/JohnCraig.jpg" class="square mx-auto d-block" alt="Portrait of Guest Experience Maximization COO John Craig">
              <p  id="leadershipName" class="card-title">John Craig</p>
              <p  id="leaderTitle" class="card-subtitle mb-2">Chief Operating Officer</p>
              <a target="_blank" href="https://www.linkedin.com/in/jhcraig/" class="stretched-link"></a>
            </div>
          </div>
        </div>
        <!-- <div class="col-sm-3 d-flex align-items-stretch">
          <div id="imageCard" class="card rounded-0" style="width: 18rem;">
            <div>
              <img id="andersonImage" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/AboutUs/AndersonCosta2.jpg" class="square mx-auto d-block" alt="Portrait of Guest Experience Maximization CTO Felix Torres">
              <p id="leadershipName" class="card-title">Anderson Costa</p>
              <p id="leaderTitle" class="card-subtitle mb-2">Director of Technology</p>
              <a target="_blank" href="https://www.linkedin.com/in/anderson-costa-781b231b/" class="stretched-link"></a>
            </div>
          </div>
        </div> -->
        <div class="col-sm-3 d-flex align-items-stretch">
          <div id="imageCard" class="card rounded-0" style="width: 18rem;">
            <div>
              <img id="kristieImage" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/AboutUs/KristieCohen1.jpg" class="square mx-auto d-block" alt="Portrait of Guest Experience Maximization CFO Kristie Cohen">
              <p id="leadershipName" class="card-title">Kristie Cohen</p>
              <p id="leaderTitle" class="card-subtitle mb-2">Chief Financial Officer</p>
              <a target="_blank" href="https://www.linkedin.com/in/kristie-cohen-25b8a7154/" class="stretched-link"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
