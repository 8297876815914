import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA  } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RibbonComponent } from './ribbon/ribbon.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SolutionComponent } from './solution/solution.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule} from '@angular/forms' ;
import { MDBBootstrapModule, CarouselModule, WavesModule } from 'angular-bootstrap-md';
import { CareersComponent } from './careers/careers.component' ;



@NgModule({
  declarations: [
    AppComponent,
    RibbonComponent,
    NavbarComponent,
    ContactFormComponent,
    SolutionComponent,
    HomeComponent,
    FooterComponent,
    AboutComponent,
    CareersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    CarouselModule,
    WavesModule
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }


