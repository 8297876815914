import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { SharedService } from './../shared.service';

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.css']
})
export class SolutionComponent implements OnInit {

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
  }

  public scrollToConnect(): void {
    this.sharedService.sendClickEvent();
  }

}
