<!-- Start the navigation bar -->
<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <div class="container">
    <a class="navbar-brand" href="https://gemax.io">
        <img id="gemLogo" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Solutions+/GEM_GuestExperienceMax_FullColor.svg" alt="Guest Experience Maximization logo">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]= "'/'" [routerLinkActive]="['is-active-route']" [routerLinkActiveOptions]="{ exact: true }">Home
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]= "'/solution'" [routerLinkActive]="['is-active-route']">Solutions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]= "'/about'" [routerLinkActive]="['is-active-route']">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://insights.gemax.io/">Insights</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]= "'/careers'" [routerLinkActive]="['is-active-route']">Careers</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- End navigation bar-->
