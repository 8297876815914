<!-- Hero Banner -->

<div id="gem-01" class="container-fluid">
  <div class="container">
       <div class="row">
          <div id="head-info" class="col-lg-8">
            <h1 id="connectWithGuest">Give Your Guests a</h1>
            <p><span id="personalLevel" class="heading"> Seamless Journey</span></p>
            <p id="heading1" class="heading-01">GEM provides customized solutions to identify and resolve</p>
            <p id="heading2" class="heading-02 float-left">any friction in your experience execution and delivery.</p>
          </div>
          <div class="col-lg-4"></div>
          <div class="container">
            <div class="row">
              <a class="main-btn" href="javascript:void(0);" (click)="scrollToConnect()">Start a Conversation with GEM</a>
            </div>
          </div>
       </div>
  </div>
</div>

<!-- Grey Banner Highlight -->
<div class="container-fluid">
  <div class="row">
  <div  id="bannerHighlight"></div>
  <div id="bannerClear"></div>
 </div>
</div>

<!-- End  Hero banner -->

<! -- Approach Section -->
<div class="container" id="approachSection">
  <div class="row">
    <div class="col-7" id="approachParagraph">
      <h1 id='ourApproach'>Our Approach</h1>
      <p>
        We create, develop, and execute the most awesome guest experience, at scale.
      </p>
      <p>
        The customer experience can be challenging to perfect. We recognize that boxed solutions do not work for every situation.
      </p>
      <p>
        The GEM team considers every unique challenge as an opportunity to innovate, combining business intelligence, cutting-edge technology, and operational excellence to maximize the experiences of your customers, guests, and patients.
      </p>
    </div>
    <div class="col-5" id="vennDiagram">
      <img id="diagram" class="mx-auto d-block" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Solutions+/Diagram-Graphics.svg" id="diagram" alt="Venn Diagram showing intersection of Business Intelligence, Technology, Operations to create Guest Experience">
    </div>
    <div class="w-100"></div>
  </div>
</div>

<div class="container-fluid" id="data">
  <div class="container">
    <div class="row">
      <div class="col-7 paragraph" id="dataParagraph">
            <h1 class="title">GEM Understands</h1>
            <h1 class="titleSub">Data</h1>
            <p>
              Begin by being Data-informed. Learn about your customers. Solve challenges before they are noticed. Our experts will guide you through the scientific intelligence of data science and how to optimize all 1st party and commercially available data points.
            </p>
            <a class="btn btn-outline-primary" id="caseStudyButton" target="_blank" rel="noopener noreferrer" href="https://gem-general-media.s3.amazonaws.com/PDF/GEM_Why_We_Need_Data_Science_in_the_Guest_Experience.pdf" role="button">See How We Did It</a>
      </div>
      <div class="col-5 points" id="dataPoints">
            <h2 class="pointsTitle">Expertise</h2>
              <ul style>
                <li>Machine Learning</li>
                <li>Data Integration & Visualization</li>
                <li>Artificial Intelligence</li>
                <li>Data Compliance</li>
              </ul>
          </div>
    </div>
  </div>
</div>

<div class="container-fluid" id="software">
  <div class="container">
    <div class="row">
      <div class="col-7 paragraph" id="softwareParagraph">
            <h1 class="title">GEM Builds 'CLOUD'</h1>
            <h1 class="titleSub">Software Solutions</h1>
            <p>
              All you need are the right tools to personalize your customer journey. Every customer journey is unique, that’s why we build bespoke technology to establish customizable strategies for a personalized experience.
            </p>
            <a class="btn btn-outline-primary" id="caseStudyButton" target="_blank" rel="noopener noreferrer" href="https://gem-general-media.s3.amazonaws.com/PDF/GEM_Leveraging-Customized-and-Microservices.pdf" role="button">See How We Did It</a>
      </div>
      <div class="col-5 points" id="softwarePoints">
            <h2 class="pointsTitle">Expertise</h2>
              <ul style>
                <li>Software Development</li>
                <li>Legacy Software Integration</li>
                <li>Agile Methodologies</li>
                <li>Incident Management</li>
              </ul>
          </div>
    </div>
  </div>
</div>

<div class="container-fluid" id="operations">
  <div class="container">
    <div class="row">
      <div class="col-7 paragraph" id="operationsParagraph">
            <h1 class="title">GEM Leads</h1>
            <h1 class="titleSub">Experience Operations</h1>
            <p>
              Exceed customer expectations and educate your teams for success by aligning your goals, current capabilities, and service offerings. The GEM Team creates a life cycle of operational excellence for you to streamline your processes.
            </p>
              <a class="btn btn-outline-primary" id="caseStudyButton" target="_blank" rel="noopener noreferrer" href="https://gem-general-media.s3.amazonaws.com/PDF/GEM_Shipboard-Team-Management.pdf" role="button">See How We Did It</a>
      </div>
      <div class="col-5 points" id="operationsPoints">
            <h2 class="pointsTitle">Expertise</h2>
              <ul style>
                <li>Integrated Consulting</li>
                <li>Process Improvement</li>
                <li>Program Management</li>
                <li>Change Management</li>
              </ul>
          </div>
    </div>
  </div>
</div>

<!-- Accordion GOES HERE for Mobile -->

<div id="accordion">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0 cardTitle">
        <row>
          <div data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            GEM Understands Data
            <img class="vector" src="assets/Images/arrow_down.svg">
          </div>
        </row>
      </h5>
    </div>
    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <p>
          Begin by being Data-informed. Learn about your customers. Solve challenges before they are noticed. Our experts will guide you through the scientific intelligence of data science and how to optimize all 1st party and commercially available data points.
        </p>
        <div class="points">
          <h2 class="pointsTitle">Expertise</h2>
            <ul style>
              <li>Machine Learning</li>
              <li>Data Integration & Visualization</li>
              <li>Artificial Intelligence</li>
              <li>Data Compliance</li>
            </ul>
        </div>
        <a class="btn btn-outline-primary" id="dataCaseStudyButton" target="_blank" rel="noopener noreferrer" href="https://gem-general-media.s3.amazonaws.com/PDF/GEM_Why_We_Need_Data_Science_in_the_Guest_Experience.pdf" role="button">See How We Did It</a>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0 cardTitle">
        <div data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          GEM Builds 'CLOUD' Software Solutions
          <img class="vector" src="assets/Images/arrow_down.svg">
        </div>
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <p>
          All you need are the right tools to personalize your customer journey. Every customer journey is unique, that’s why we build bespoke technology to establish customizable strategies for a personalized experience.
        </p>
        <div class="points">
          <h2 class="pointsTitle">Expertise</h2>
            <ul style>
              <li>Software Development</li>
              <li>Legacy Software Integration</li>
              <li>Agile Methodologies</li>
              <li>Incident Management</li>
            </ul>
        </div>
        <a class="btn btn-outline-primary" id="softwareCaseStudyButton" target="_blank" rel="noopener noreferrer" href="https://gem-general-media.s3.amazonaws.com/PDF/GEM_Leveraging-Customized-and-Microservices.pdf" role="button">See How We Did It</a>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0 cardTitle">
        <div data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          GEM Leads Experience Operations
          <img class="vector" src="assets/Images/arrow_down.svg">
        </div>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <p>
          Exceed customer expectations and educate your teams for success by aligning your goals, current capabilities, and service offerings. The GEM Team creates a life cycle of operational excellence for you to streamline your processes.
        </p>
        <div class="points">
          <h2 class="pointsTitle">Expertise</h2>
            <ul style>
              <li>Integrated Consulting</li>
              <li>Process Improvement</li>
              <li>Program Management</li>
              <li>Change Management</li>
            </ul>
        </div>
        <a class="btn btn-outline-primary" id="operationsCaseStudyButton" target="_blank" rel="noopener noreferrer" href="https://gem-general-media.s3.amazonaws.com/PDF/GEM_Shipboard-Team-Management.pdf" role="button">See How We Did It</a>
      </div>
    </div>
  </div>
</div>


<div class="container" id="roadwayImage">
  <img id="methodology" class="mx-auto d-block" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Solutions+/Methodology+Graphic.svg" alt="Guest Experience Maximization flowchart describing work process">
</div>
