import { Component , OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ContactFormComponent } from './contact-form/contact-form.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild(ContactFormComponent) contactFormComponent: ContactFormComponent;

  constructor(private router: Router) { }

  title = 'gemSite';

  ngOnInit() {
      this.router.events.subscribe((evt) => {
          if (!(evt instanceof NavigationEnd)) {
              return;
          }
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          this.contactFormComponent.refreshContactForm();
      });
  }
}
