<!-- Hero Banner -->

<div id="gem-01" class="container-fluid">
  <div class="container">
       <div class="row">
          <div id="head-info" class="col-lg-8">
            <h1 id="connectWithGuest">Connect with your Guests</h1>
            <p><span id="personalLevel" class="heading"> on a Personal Level</span></p>
            <p id="heading1" class="heading-01">Elevate your bottom line and transcend</p>
            <p id="heading2" class="heading-02 float-left">customer expectations.</p>
          </div>
          <div class="col-lg-4"></div>
          <div class="container">
            <div class="row">
              <a class="main-btn" href="javascript:void(0);" (click)="scrollToConnect()">Start a Conversation with GEM</a>
            </div>
          </div>
       </div>
  </div>
</div>

<!-- Grey Banner Highlight -->
<div class="container-fluid">
  <div class="row">
  <div id="bannerHighlight"></div>
  <div id="bannerClear"></div>
 </div>
</div>

<!-- End  Hero banner -->

 <!-- Quote Section -->
 <div id="quoteBackground"class="container-fluid">
   <div class="container" id="quoteContainer">
     <img id="leftCornerArrow" class="float-Left" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Homepage/Left+Arrow.svg" alt="Red vertical and horizontal intersecting arrows">
     <div id="contentWrapper" class="content-wrapper">
       <h1 id="SteveQuote">“To gain loyalty, build brand equity, and thrive, brands need to cultivate relationships with customers by delivering a maximized full-journey experience.”</h1>
       <h3 id="SteveName"><span style="font-weight:bold">Steve Cohen</span>, President & CEO</h3>
     </div>
     <img id="rightCornerArrow" class="float-right" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Homepage/Right+Arrow.svg" alt="Red vertical and horizontal intersecting arrows">
   </div>
 </div>

 <!-- Experienced Section -->
 <div class="container-fluid">
   <div id="experiencedContainer" class="container">
     <div class="row" id="experiencedRow">
       <h1 id="experiencedTitle">Personalized Experiences Lead to Loyalty</h1>
     </div>
     <div id="experiencedContent" class="row">

       <div id="experienceParagraph"class="col-md-6">
         <p>
          Consumer loyalty has a big impact on brands; earning it is not easy. In order to build loyalty, brands need to delight customers and establish trust. Any and all interactions between brand and customer require fanatic study of detail and flawless execution of the entire experience.
         </p>
         <p>
           By learning the preferences of each individual and applying that knowledge to personalize every experience, customers will know you are paying attention and that they matter.
         </p>
         <p>
           Consumers want an experience that's engaging and creates memories. By maximizing the guest experience and providing memorable experiences, brands build trust, encourage loyalty, and positively impact a brand’s economics.
         </p>
        </div>
       <div class="col-md-6" id="homePageVideo">
         <video width="105%" id="video" height="auto"src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Homepage/GEM_Intro_062619_V2.mp4" controls poster="https://gem-general-media.s3.amazonaws.com/GEM+Website/Homepage/Photo-GEM+Logo.png">
         </video>
       </div>
      </div>
   </div>
 </div>

<!-- End Experienced Section -->

<!-- Solutions Section -->
<div id="solutionsBackground"class="container-fluid">
    <div class="row mx-auto d-block">
      <h1 id="solutionsTitle">Solutions - Bold Steps Forward.</h1>
    </div>
    <div class="row mx-auto d-block">
      <h2 id="solutionsSubTitle">See how we've helped ambitious clients achieve extraordinary outcomes.</h2>
    </div>
</div>

<div class="container-fluid" id="solutionsCardsContainer">
  <div id="threeColumnContainer" class="container">
    <div class="row" id="threeColumnRow">
      <div class="col-lg-4 d-flex align-items-stretch solutionCard">
        <div id="cardWidth" class="card rounded-0 mx-auto" style="width: 25rem;">
        <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Homepage/Business-Intelligence.jpg" class="card-img-top threeColumnImage"   alt="Photo of hands using a smartphone with graphic overlay">
        <div class="card-body">
          <h2 class="card-title">Business Intelligence and Big Data</h2>
          <p class="card-text">Understand your customers through intelligence and optimized data</p>
        </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch solutionCard">
        <div id="cardWidth" class="card rounded-0 mx-auto" style="width: 25rem;">
        <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Homepage/Cutting-Edge-Technology.jpg" class="card-img-top threeColumnImage"  alt="An employee viewing a panel of three computer monitors and working with a tablet">
        <div class="card-body">
          <h2 class="card-title">Cutting-edge Technology</h2>
          <p class="card-text">Put your data into action and personalize experiences with customized cloud-based software</p>
        </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch solutionCard">
        <div id="cardWidth" class="card rounded-0 mx-auto" style="width: 25rem;">
        <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Homepage/Operational-Excellence.jpg" class="card-img-top threeColumnImage"  alt="Three coworkers looking at dual computer monitor panel">
        <div class="card-body">
          <h2 class="card-title">Operational Excellence</h2>
          <p class="card-text">Streamline your processes, accomplish the most challenging objectives to continually deliver improved experiences</p>
        </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch solutionCard">
        <div id="cardWidth" class="card rounded-0 mx-auto" style="width: 25rem;">
        <button id="mobileSolutionsButton" type="button" class="btn btn-primary btn-lg btn-block" [routerLink]= "'/solution'">View Our Solutions</button>
      </div>
      </div>
    </div>
    <div class="row">
      <button id="solutionsButton" type="button" class="btn btn-primary btn-lg btn-block" [routerLink]= "'/solution'">View Our Solutions</button>
    </div>
  </div>
</div>
