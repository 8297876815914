import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './../shared.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  successResponse = false;
  failureResponse = false;

  baseURL = 'https://w1ty0wwck6.execute-api.us-east-1.amazonaws.com/contact/connect-with-gem';

  clickEventsubscription: Subscription;

  @ViewChild('contact') contact;

  constructor(private sharedService: SharedService, private http: HttpClient, private formBuilder: FormBuilder) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.scrollToContact();
      });
   }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      senderEmail: ['', [Validators.required, Validators.email]],
      jobTitle: [''],
      companyName: [''],
      message: ['', [Validators.required, Validators.maxLength(255)]]
  });
  }

  public scrollToContact() {
    this.contact.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  // convenience getter for easy access to form fields
  get fetch() { return this.registerForm.controls; }

  submitContactForm(contactInfo) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    const body = JSON.stringify(contactInfo);
    return this.http.post(this.baseURL, body).subscribe((res: any) => { // not callback
      console.log(res);
      this.registerForm.reset();
      this.successResponse = true;
    }, error => {
      console.error('Error', error);
      this.failureResponse = true;
    });
  }

  refreshContactForm() {
    this.submitted = false;
    this.successResponse = false;
    this.failureResponse = false;
  }

}
