<!-- Hero Banner -->

<div id="gem-01" class="container-fluid">
  <div class="container">
       <div class="row">
          <div id="head-info" class="col-lg-6">
            <h1 id="connectWithGuest">Building Solutions Together</h1>
            <p id="heading1" class="heading-01">Our people-centered culture encourages</p>
            <p id="heading2" class="heading-02 float-left">innovation through teamwork.</p>
          </div>
          <div class="col-lg-6"></div>
          <div class="container">
            <div class="row">
              <a class="main-btn" href="javascript:void(0);" (click)="scrollToConnect()">Start a Conversation with GEM</a>
            </div>
          </div>
       </div>
  </div>
</div>

<!-- Grey Banner Highlight -->
<div class="container-fluid">
  <div class="row">
  <div  id="bannerHighlight"></div>
  <div id="bannerClear"></div>
 </div>
</div>

<!-- End  Hero banner -->

<!-- People Section -->
<div class="container" id="peopleContainer">
    <div class="row">
      <h1 id="peopleTitle">People Are at Our Core</h1>
    </div>
    <div id="peopleContent" class="row">
        <div id="peopleParagraph"class="col-md-6">
          <p>
            We believe that people are what matters most. Upholding a human-centric approach to enhancing experiences is only part of our success story. We achieve success by valuing an autonomous, innovative culture of empowered team members. We encourage our TEAM to ask hard questions, analyze and test concepts to find the right solutions, and make decisions to rapidly deliver results.
          </p>
        </div>
        <div class="col-md-6">
          <div id="peopleImage">
            <img id="teamPhoto" class="card-img-button" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/GEM-Team_v1.jpg" alt="Guest Experience Maximization team standing in front of a pool in Doral, Florida">

          </div>
        </div>
      </div>
</div>
<!-- End People Section-->

<!-- Who Section-->
<div id="differenceContainer"class="container-fluid">
  <div class="row">
    <h1 id="headSubtitle"class="mx-auto d-block">who</h1>
  </div>
  <div class="row">
    <h1 id="headTitle" class="mx-auto d-block">we are</h1>
  </div>
<div class="container" id="whoWeAreContainer">
  <div class="row">
    <div class="col-sm-4">
      <div id="whatTitleRow" class="row">
        <h2 id="whatTitle" class="mx-auto d-block">Compassionate</h2>
        </div>
    </div>
    <div class="col-sm-4">
      <div id="whatTitleRow" class="row">
        <h2 id="whatTitle" class="mx-auto d-block">Determined</h2>
        </div>
    </div>
    <div class="col-sm-4">
      <div id="whatTitleRow" class="row">
        <h2 id="whatTitle" class="mx-auto d-block">Curious</h2>
        </div>
    </div>
  </div>

<!-- Title container ends and the Column Content Begins-->
<div class="row">
<div id="compassionateParagraphColumn"class="col-sm-4 d-flex align-items-stretch">
  <div id="whoWeAreParagraphCard" class="card rounded-0">
    <div class="card-body">
      <p id="whoWeAreParagraph" class="card-text">Thinking of others, being proactive, and putting our team and partners first ensures success.</p>
    </div>
    <img id="compassionateImage" class="card-img-button" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/compassionate.jpg" alt="Group of coworkers standing in a circle with their arms over each other's shoulders">
  </div>
</div>
<div id="determinedParagraphColumn"class="col-sm-4 d-flex align-items-stretch">
  <div id="whoWeAreParagraphCard" class="card rounded-0">
    <div class="card-body">
      <p id="whoWeAreParagraph" class="card-text">Our unparalleled approach to problem-solving does not allow anything to get in the way of clear, discernible, and positive results.
      </p>
    </div>
    <img id="determinedImage" class="card-img-button" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/determined.jpg" alt="Coworkers standing at a table, looking at a tablet">
  </div>
</div>
<div id="curiousParagraphColumn"class="col-sm-4 d-flex align-items-stretch">
  <div id="whoWeAreParagraphCard" class="card rounded-0">
    <div class="card-body">
      <p id="whoWeAreParagraph" class="card-text">We fanatically perform root cause analysis to ensure future challenges are met with the right solutions.
      </p>
    </div>
    <img id="curiousImage" class="card-img-button" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/curious.jpg" alt="Coworkers looking at a document with sunflare and transparent graphs over them">
  </div>
</div>
</div>
</div>
</div>

<div class="container" id="differenceMobileContainer">
  <div class="row">
    <h1 id="headSubtitle"class="mx-auto d-block">who</h1>
  </div>
  <div class="row">
    <h1 id="headTitle" class="mx-auto d-block">we are</h1>
  </div>
  <div class="col-7" id ="whatMakesUsDifferentCol">
      <div class="row">
          <div class="whatMakesUsDifferentItemHeader">
            Compassionate
          </div>
      </div>
      <div class="row">
            <div class="whatMakesUsDifferentItemParagraph">
              Thinking of others, being proactive, and putting our team and partners first ensures success.
            </div>
      </div>
      <div class="row">
        <div class="whatMakesUsDifferentItemHeader">
          Determined
        </div>
      </div>
      <div class="row">
            <div class="whatMakesUsDifferentItemParagraph">
              Our unparalleled approach to problem-solving does not allow anything to get in the way of clear, discernible, and positive results.
            </div>
      </div>
      <div class="row">
        <div class="whatMakesUsDifferentItemHeader">
          Curious
        </div>
    </div>
    <div class="row">
          <div class="whatMakesUsDifferentItemParagraph">
            We fanatically perform root cause analysis to ensure future challenges are met with the right solutions.
          </div>
    </div>
  </div>
</div>

<div id="whoWeAreBar"class="container-fluid">
 </div>

<!-- End Who Section-->
<!-- What Section-->

<div id="differenceContainer"class="conainer-fluid">
  <div class="row">
    <h1 id="headSubtitle"class="mx-auto d-block">what</h1>
  </div>
  <div class="row">
    <h1 id="headTitle" class="mx-auto d-block">we offer</h1>
  </div>
  <div class="container" id="whatWeOfferContainer">
  <div class="row">

    <div id="whatWeOfferCard" class="card rounded-0 mx-auto" style="width: 29%;">
      <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Benefits.svg" class="card-img-top" alt="Illustration of a shield with a checkmark in the middle" id="whatWeOfferImage">
      <div class="card-body" id="whatWeOfferCardBody">
        <h2 class="card-title">Benefits</h2>
        <p class="card-text">Stay healthy and save for retirement with health insurance, 401k matching, and an overall great benefits package.</p>
      </div>
    </div>

    <div id="whatWeOfferCard" class="card rounded-0 mx-auto" style="width: 29%;">
        <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Compensation.svg" class="card-img-top" alt="Illustration of arrows rotating between a circle with a star and a circle with a dollar sign" id="whatWeOfferImage">
      <div class="card-body" id="whatWeOfferCardBody">
          <h2 class="card-title">Compensation</h2>
          <p class="card-text">Earn generous compensation, including salary, bonus, and company-sponsored benefits.</p>
      </div>
    </div>

    <div id="whatWeOfferCard" class="card rounded-0 mx-auto" style="width: 29%;">
        <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Education.svg" class="card-img-top" alt="Illustration of a certificate with ribbon" id="whatWeOfferImage">
      <div class="card-body" id="whatWeOfferCardBody">
            <h2 class="card-title">Education</h2>
            <p class="card-text">Continue building your knowledge through GEM sponsored academic programs.</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div id="whatWeOfferCard" class="card rounded-0 mx-auto" style="width: 29%;">
      <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Flexibility.svg" class="card-img-top" alt="Illustration of double-sided verticle arrow putting downward pressure on three horizontal lines below" id="whatWeOfferImage">
     <div class="card-body" id="whatWeOfferCardBody">
          <h2 class="card-title">Flexibility</h2>
          <p class="card-text">Enjoy the benefits of a remote work policy that promotes a better work-life balance.</p>
     </div>
    </div>

    <div id="whatWeOfferCard" class="card rounded-0 mx-auto" style="width: 29%;">
      <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Refreshments.svg" class="card-img-top" alt="Illustration of a cup of coffee and an apple" id="whatWeOfferImage">
      <div class="card-body" id="whatWeOfferCardBody">
        <h2 class="card-title">Drinks and More</h2>
        <p class="card-text">Connect with team members and re-energize over a healthy snack or a cup of coffee.</p>
      </div>
    </div>

  <div id="whatWeOfferCard" class="card rounded-0 mx-auto" style="width: 29%;">
      <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Activities.svg" class="card-img-top" alt="Illustration of two figures running" id="whatWeOfferImage">
    <div class="card-body" id="whatWeOfferCardBody">
      <h2 class="card-title">Activities</h2>
      <p class="card-text">Build relationships through out-of-office outings and adventures with cross-department team members.</p>
    </div>
  </div>

  </div>
</div>
</div>
<!--End What Section-->

<!-- Begin What We Offer Mobile Section -->

<div class=" container-fluid mobileWhatWeOffer" >
  <div class="row">
    <h1 id="headSubtitle"class="mx-auto d-block">what</h1>
  </div>
  <div class="row">
    <h1 id="headTitle" class="mx-auto d-block">we offer</h1>
  </div>
  <div class="row" id="whatWeOfferRow">
    <div class="col-3">
       <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Benefits.svg" class="card-img-top" alt="Illustration of a shield with a checkmark in the middle" id="mobileWhatWeOfferImage">
      </div>
    <div class="col-9"> <div class="whatWeOfferRowBody">
      <h2 class="row-title">Benefits</h2>
      <p class="row-text">Stay healthy and save for retirement with health insurance, 401k matching, and an overall great benefits package.</p>
    </div>
  </div>
  </div>

  <div class="row" id="whatWeOfferRow">
    <div class="col-3">
      <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Compensation.svg" class="card-img-top" alt="Illustration of arrows rotating between a circle with a star and a circle with a dollar sign" id="mobileWhatWeOfferImage">
    </div>
    <div class="col-9"> <div class="whatWeOfferRowBody">
      <h2 class="row-title">Compensation</h2>
      <p class="row-text">Earn generous compensation, including salary, bonus, and company-sponsored benefits.</p>
    </div>
  </div>
  </div>

  <div class="row" id="whatWeOfferRow">
   <div class="col-3">
      <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Education.svg" class="card-img-top" alt="Illustration of a certificate with ribbon" id="mobileWhatWeOfferImage">
    </div>
   <div class="col-9"><div class="whatWeOfferRowBody">
      <h2 class="row-title">Education</h2>
      <p class="row-text">Continue building your knowledge through GEM sponsored academic programs.</p>
    </div>
  </div>
  </div>

  <div class="row" id="whatWeOfferRow">
    <div class="col-3">
       <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Flexibility.svg" class="card-img-top" alt="Illustration of double-sided verticle arrow putting downward pressure on three horizontal lines below" id="mobileWhatWeOfferImage">
      </div>
    <div class="col-9"> <div class="whatWeOfferRowBody">
      <h2 class="row-title">Flexibility</h2>
      <p class="row-text">Enjoy the benefits of a remote work policy that promotes a better work-life balance.</p>
    </div>
  </div>
  </div>

  <div class="row" id="whatWeOfferRow">
    <div class="col-3">
      <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Refreshments.svg" class="card-img-top" alt="Illustration of a cup of coffee and an apple" id="mobileWhatWeOfferImage">
    </div>
    <div class="col-9"> <div class="whatWeOfferRowBody">
      <h2 class="row-title">Drinks and More</h2>
      <p class="row-text">Connect with team members and re-energize over a healthy snack or a cup of coffee.</p>
    </div>
  </div>
  </div>

  <div class="row" id="whatWeOfferRow">
    <div class="col-3">
      <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/Activities.svg" class="card-img-top" alt="Illustration of two figures running" id="mobileWhatWeOfferImage">
    </div>
    <div class="col-9"> <div class="whatWeOfferRowBody">
      <h2 class="row-title">Activities</h2>
      <p class="row-text">Build relationships through out-of-office outings and adventures with cross-department team members.</p>
    </div>
  </div>
  </div>

</div>

<!-- End What We Offer Mobile Section -->

<!-- Job Listings Banner -->

<section id="jobBanner" >
  <div id="jobContainer" class="container-fluid">
    <div class = "container">
        <div class="padding-header" id="jobsPaddingHeader">
          <div class="row">
                    <div id="jobsButtonWrapper" class="btn-margin">
                      <a id="jobsButton" class="main-btn" href="https://lms78.applytojob.com/apply/" target="blank">See All Openings</a>
                    </div>
              </div>
          </div>
      </div>
    </div>
</section>

<!-- End Job Listings Banner -->

<div id="carouselContainer" class="container-fluid">
  <div class="container">
    <div class="row">
      <div id ="carouselHeader">What Our Employees Are Saying</div>
    </div>
    <div class="row">
      <mdb-carousel [interval]="10000" [animation]="'slide'"  class="carousel-slide">
        <mdb-carousel-item>
          <div class="col-6">
            <img class="carouselImage" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/NickCraig.jpg" alt="Portrait of Guest Experience Maximization team member Nick Craig">
          </div>
          <div class="col-4 testimonial" >
            <p class="testimonialParagraph" id="nickCraigParagraph">
              “There are only a few moments in life I’d consider life-altering on a grand scale, and I consider joining GEM one of them. Between creating lifelong friendships with people across the world and viewing the sights people wait their entire lives to see, there is never a dull moment. I have learned more in a year than I have in a decade and look forward to more amazing experiences with this team.”
            </p>
            <p class="carouselPositionTitle" id="nickCraigPositionTitle">
              Nick Craig, Senior Shipboard Incident Manager
            </p>
          </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
          <div class="col-6">
            <img class="carouselImage" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/MelanieRomeo.jpg" alt="Portrait of Guest Experience Maxmization team member Melanie Romeo">
          </div>
          <div class="col-4 testimonial">
            <p class="testimonialParagraph">
              “Working for GEM has become more than just my job. Every single day we’re changing thousands of lives and working to give guests the most enhanced experiences out there. When you’re changing lives and doing it with people just as passionate and enthusiastic as you are, it’s no longer a job but a lifestyle.”
            </p>
            <p class="carouselPositionTitle">
              Melanie Neciosup, Senior xOps Team Leader
            </p>
          </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
          <div class="col-6">
            <img class="carouselImage" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/JoshFord.jpg" alt="Portrait of Guest Experience Maxmization team member Josh Ford">
          </div>
          <div class="col-4 testimonial">
            <p class="testimonialParagraph">
              “Knowing that there are new challenges to come into head-on is my motivation. I have the opportunity to work in partnership and collaboration with different teams from all backgrounds, focuses, and skills to find creative solutions for our program and collective objectives.”
            </p>
            <p class="carouselPositionTitle">
              Josh Ford, Senior Technical Integration Specialist
            </p>
          </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
          <div class="col-6">
            <img class="carouselImage" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/MariaMartinez.jpg" alt="Portrait of Guest Experience Maximization team member Maria Martinez">
          </div>
          <div class="col-4 testimonial">
            <p class="testimonialParagraph">
              “I’ve had the opportunity to learn about what it takes to create magical customer experiences, work with brilliantly-minded people from many different cultures and backgrounds, and create user-friendly technologies that impact travelers worldwide. While I might not be able to change the world itself, I can definitely change the world for someone else.”
            </p>
            <p class="carouselPositionTitle">
              Maria Martinez, Senior UX/UI Designer
            </p>
          </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
          <div class="col-6">
            <img class="carouselImage" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/Careers/MohammedElTawab.jpg" alt="Portrait of Guest Experience Maximization team member Mohammed El-Tawab">
          </div>
          <div class="col-4 testimonial">
            <p class="testimonialParagraph" id="mohamedParagraph">
              “Maximizing experiences is our mission and we believe that customer happiness should always come first. We work hard to understand what people need, and then create technologies that help our clients reach the highest standards for guests’ satisfaction. Seeing what our team is capable of and the outcome of our results makes me want to come to work every day.”
            </p>
            <p class="carouselPositionTitle" id="mohamedPositionTitle">
              Mohamed El-Tawab, Software Engineer II
            </p>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </div>
</div>
