import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from './../shared.service';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})

export class AboutComponent implements OnInit, OnDestroy {

  wordCycle: string;
  wordList: Array<string> = ['enhance', 'boost', 'fortify', 'deepen', 'strengthen'];
  ngUnsubscribe: Subject<any> = new Subject();

  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    // runs on load
    this.wordCycle = this.wordList[0];
    interval(2000)
      // unsubscribe from interval when the component is destroyed, averting any memory leak
      .pipe(takeUntil(this.ngUnsubscribe))
      // subscribe to interval
      .subscribe(() => {
        // find the current word index in the list, and display next word
        const wordIndex = this.wordList.findIndex((wordCycle: string) => wordCycle === this.wordCycle);
        const nextWord = this.wordList[wordIndex + 1];
        this.wordCycle = nextWord ? nextWord : this.wordList[0];
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public scrollToConnect(): void {
    this.sharedService.sendClickEvent();
  }

}
