<div *ngIf="!successResponse" #contact class="form-container">
  <form class="form needs-validation" [formGroup]="registerForm" #contactForm = "ngForm" (ngSubmit) = "submitContactForm(contactForm.value)" novalidate>
    <div class="form-text">
    <h1 id="connectWithGEMHeader">CONNECT WITH GEM</h1>
    <p id="connectWithGEMSubHeader">We are here to help you succeed. Reach out to us today at <a id="contactFormEmail" href = "mailto: contact@gemax.io">contact@gemax.io</a> or fill out the form below and we’ll be in touch.</p>
    </div>
    <div id="failureContainer" *ngIf="failureResponse" class="row">
      <svg class="bi bi-exclamation-triangle" width="1em" height="1em" viewBox="0 0 16 16" fill="#FF6666" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M7.938 2.016a.146.146 0 00-.054.057L1.027 13.74a.176.176 0 00-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 00.066-.017.163.163 0 00.055-.06.176.176 0 00-.003-.183L8.12 2.073a.146.146 0 00-.054-.057A.13.13 0 008.002 2a.13.13 0 00-.064.016zm1.044-.45a1.13 1.13 0 00-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" clip-rule="evenodd"/>
        <path d="M7.002 12a1 1 0 112 0 1 1 0 01-2 0zM7.1 5.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0L7.1 5.995z"/>
      </svg>
      <p id="failureText"> Something went wrong, please try again</p>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
          <input type="text" formControlName="name" class="form-control" placeholder="First and Last Name" [ngClass]="{ 'is-invalid': submitted && fetch.name.errors }" >
          <div class="name-asterisk" *ngIf="fetch.name.errors">*</div>
          <div *ngIf="submitted && fetch.name.errors" class="invalid-feedback">
            <div *ngIf="fetch.name.errors.required">The First and Last Name is required</div>
          </div>
        </div>
        <div class="form-group col-md-6">
          <input type="email" formControlName = "senderEmail" class="form-control" placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && fetch.senderEmail.errors }">
          <div class="email-asterisk" *ngIf="fetch.senderEmail.errors && fetch.senderEmail.errors.required">*</div>
          <div *ngIf="submitted && fetch.senderEmail.errors" class="invalid-feedback">
            <div *ngIf="fetch.senderEmail.errors.required">The Email address is required</div>
            <div *ngIf="fetch.senderEmail.errors.email">Please enter a valid email address</div>
          </div>
        </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
          <input type="text" formControlName = "jobTitle" class="form-control" id="jobTitle" placeholder="Job Title">
      </div>
      <div class="form-group col-md-6">
          <input type="text" formControlName = "companyName" class="form-control" id="companyName" placeholder="Company Name">
      </div>
    </div>
    <div class="form-row">
        <textarea class="form-control required" formControlName = "message" id="Message" placeholder="Message..." rows="3" [ngClass]="{ 'is-invalid': submitted && fetch.message.errors }"></textarea>
        <div class="message-asterisk" *ngIf="fetch.message.errors && fetch.message.errors.required">*</div>
        <div *ngIf="submitted && fetch.message.errors" class="invalid-feedback">
          <div *ngIf="fetch.message.errors.required">The message is required</div>
          <div *ngIf="fetch.message.errors.maxlength">Your message exceeds the character limit of 255</div>
        </div>
    </div>
      <div>
        <button id="submitButton" type="submit" class="btn submit-button">Submit</button>
      </div>
  </form>
</div>
<div *ngIf="successResponse" id="thankYouBg" class="container-fluid">
  <div class="container">
    <div class="row">
      <img id="checkIcon" src="https://gem-general-media.s3.amazonaws.com/GEM+Website/circle_ok.svg" alt="Illustration of a circle with a checkmark" class="center">
    </div>
    <div class="row">
      <h1 id="thankYou"class="mx-auto d-block">Thank you!</h1>
    </div>
    <div class="row">
      <p id="thankyYouPara" class="mx-auto d-block">Thank you for getting in touch! One of our team members will be getting back to you soon.</p>
    </div>
    <hr style="height: 2px; color:#006068;">
    <div class="row">
      <p id="thankyYouPara" class="mx-auto d-block">Learn more about how GEM is impacting the customer experience industry.</p>
    </div>
    <div class="row">
      <p id="thankyYouPara" class="mx-auto d-block connectWihUs">Connect with Us on</p>
    </div>
    <div class="row">
      <div id="LinkedIn" class="col-12">
        <a href="https://www.linkedin.com/company/gemax-io">
          <img src="https://gem-general-media.s3.amazonaws.com/GEM+Website/LinkedIn-Logo.png" alt="LinkedIn logo" class="center">
        </a>
      </div>
    </div>
  </div>
</div>
